import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [];

export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});
