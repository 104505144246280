<template>
    <div class="sorting   fx-row">
        <div class="swiper-container">
            <div class="tags" v-if="searchModel.searchLookups != null && (isAnySelected(searchModel.searchLookups.contentTypes) || isAnySelected(searchModel.allSubjects))">
                <template v-if="isAnySelected(searchModel.searchLookups.contentTypes)">
                    <div class="swiper-slide" v-for="type in searchModel.searchLookups.contentTypes" :key="type.id">
                        <template v-if="type.isSelected">
                            <span class="tag">{{type.title}}</span>
                            <i class="icon icon-close" @click="type.isSelected = !type.isSelected; onFilter()"></i>
                        </template>
                    </div>
                </template>
                <template v-if="isAnySelected(searchModel.allSubjects)">
                    <div class="swiper-slide" v-for="subject in searchModel.allSubjects" :key="subject.id">
                        <template v-if="subject.isSelected">
                            <span class="tag">{{subject.title}}</span>
                            <i class="icon icon-close" @click="unselectSubject(subject)"></i>
                        </template>
                    </div>
                </template>

                <span v-if="searchModel.searchLookups != null && (isAnySelected(searchModel.searchLookups.contentTypes) || isAnySelected(searchModel.allSubjects))" @click="removeAllSelectedItems()">{{$t("DeleteAll")}}</span>

            </div>

            <div class="swiper-button swiper-button-next"><span class="icon-arrow-down-bold"></span></div>
            <div class="swiper-button swiper-button-prev"><span class="icon-arrow-down-bold"></span></div>
        </div>

        <div class="orientaion">
            <i class="icon icon-list toggler" :title='$t("ListView")' :class="{active: searchModel.isListView}" @click="searchModel.isListView = true;"></i>
            <i class="icon icon-grid toggler" :title='$t("GridView")' :class="{active: !searchModel.isListView}" @click="searchModel.isListView = false;"></i>
        </div>
    </div>

</template>


<script>
    /* eslint-disable */

    import $ from "jquery";
    import SearchModel from "./SearchModel";

    var Swiper = window.Swiper;


    export default {
        name: "SearchFiltersBasket",
        data: function () {
            return {
                searchModel: window.searchModel ? window.searchModel : (window.searchModel = new SearchModel(this.$i18n, this.$http, window.searchLookups, this.$router))
            };
        },

        mounted: function () {
            this.initSwiper();
            $(window).on('resize', function () {
                this.initSwiper();
            });
        },
        updated: function () {
            this.searchModel.onUpdated();
            this.initSwiper();
        },
        methods: {
            unselectAllOfSpecificType: function (selectedCategory) {
                return this.searchModel.unselectAllOfSpecificType(selectedCategory);
            },
            isAllSubSubjectsSelected: function (subject) {
                return this.searchModel.isAllSubSubjectsSelected(subject);
            },
            isAnySelected: function (items) {
                return this.searchModel.isAnySelected(items);
            },
            onFilter: function () {
                return this.searchModel.onFilter();
            },
            constructUrl: function (queryString, paramName, items) {
                return this.searchModel.constructUrl(queryString, paramName, items);
            },
            removeAllSelectedItems: function () {
                return this.searchModel.removeAllSelectedItems();
            },
            unselectSubject(subject) {
                subject.isSelected = !subject.isSelected;

                if (this.searchModel.filterByUserPreferences) {
                    if (!this.isAnySelected(this.searchModel.allSubjects)) {
                        this.searchModel.filterByUserPreferences = false;
                    }
                }

                this.onFilter();
            },
            initSwiper: function () {
                if ($(window).width() > 1024) {
                    var swiperOptions = {
                        freeMode: true,
                        spaceBetween: 0,
                        speed: 500,
                        grabCursor: false,
                        slidesPerView: 'auto',
                        slidesPerGroup: 4,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        breakpoints: {
                            1024: {
                                slidesPerGroup: 2
                            },
                            768: {
                                slidesPerGroup: 1
                            }
                        }
                    };
                    var swiper = new Swiper("#searchFilters .swiper-container", swiperOptions);
                }
            },
            setIsListViewInCookie: function (isListView) {

                var uiPreferencesCookie = getCookie("UIPreferences");
                var uiPreferences = uiPreferencesCookie ? $.parseJSON(uiPreferencesCookie) : {};

                uiPreferences.isSearchListView = isListView;
                setCookie("UIPreferences", JSON.stringify(uiPreferences), this.searchModel.baseDomain);
            }
        },
        watch: {
            "searchModel.isListView": function () {
                $("div.results-cont").toggleClass("list-view", this.searchModel.isListView);
                var $cards = $("div.results-cont").find("div.listing-card-wrp");

                this.searchModel.isListView ? $cards.removeClass('col-lg-4 col-xl-3') : $cards.addClass('col-lg-4 col-xl-3');

                var $descriptions = $cards.find(".item-disc");
                if (this.searchModel.isListView) {
                    this.setIsListViewInCookie(this.searchModel.isListView);
                    $descriptions.show();

                }
                else {
                    this.setIsListViewInCookie(this.searchModel.isListView);
                    $descriptions.hide();

                }

            },
        }
    };
</script>
