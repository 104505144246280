export const PrimeVueLocale = {
    en: {
        firstDayOfWeek: 0,
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'mm/dd/yy',
        weekHeader: 'Wk'
    },
    ar: {
        firstDayOfWeek: 0,
        dayNames: ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعه", "السبت"],
        dayNamesShort: ["أحد", "أثنين", "ثلاث", "أربع", "خميس", "جمعه", "سبت"],
        dayNamesMin: ["أحد", "أثنين", "ثلاث", "أربع", "خميس", "جمعه", "سبت"],
        monthNames: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
        monthNamesShort: ["ينا", "فبر", "مار", "أبر", "ماي", "يون", "يول", "أغسط", "سبت", "أكت", "نوف", "ديس"],
        today: 'اليوم',
        clear: 'مسح',
        dateFormat: 'mm/dd/yy',
        weekHeader: 'أس'
    },
    fr: {
        firstDayOfWeek: 0,
        dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: 'Aujourdhui',
        clear: 'Dégager',
        dateFormat: 'mm/dd/yy',
        weekHeader: 'Semaine'
    }
}


