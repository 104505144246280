<template>
    <div id="mobile-filter-dropdown" class="mobile-filter-dropdown dropdown" v-if="searchModel.searchLookups">
        <button class="dropdown-toggle mobile-dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="openMobilePopup()"><span class="icon-filter"></span></button>
        <div class="dropdown-menu multi-level-dropdown multi-select shadow" v-on:click.stop>
            <div class="dropdown-menu-header">
                <div class="back-btn-cont">
                    <span>{{$t("FilterResults")}}</span>
                    <button type="button" data-action="back" data-target="this" class="select-all-btn slide-menu__control">{{$t("Back")}}</button>
                </div>
            </div>
            <div class="slide-menu" id="filter-menu-mobile">

                <ul id="first-level">
                    <li v-if="mobileSearchLookups.contentTypes && mobileSearchLookups.contentTypes.length > 0" @click="openSub($event, null)">
                        <a class="topic-title dropdown-toggle">
                            {{$t("Types")}}
                        </a>
                        <ul class="sub-list">
                            <li class="dropdown-menu-header">
                                <div class="select-all-cont">
                                    <span> {{$t("Types")}}</span>
                                    <button class="select-all-btn" @click="searchModel.selectOrUnSelectItems(mobileSearchLookups.contentTypes, !searchModel.isAllSelected(mobileSearchLookups.contentTypes))" v-text='searchModel.isAllSelected(mobileSearchLookups.contentTypes) ? $t("DeselectAll") : $t("SelectAll")'></button>
                                </div>
                            </li>
                            <li class="items-wrapper">
                                <div class="dropdown-item" :class="{selected: contentType.isSelected}" href="#" :title="contentType.title" @click="contentType.isSelected = !contentType.isSelected" v-for="contentType in mobileSearchLookups.contentTypes" :Key="contentType.id">
                                    <a @click="openSub($event, null)">
                                        {{contentType.title|truncate(35)}}
                                    </a>
                                </div>
                            </li>

                        </ul>
                    </li>

                    <!--<li v-if="mobileSearchLookups.Providers && mobileSearchLookups.Providers.length > 0" @click="openSub($event, null)">
                      <a class="topic-title dropdown-toggle">
                        {{$t("Providers")}}
                      </a>
                      <ul class="sub-list">
                        <li class="dropdown-menu-header">
                          <div class="select-all-cont">
                            <span>{{$t("Providers")}}</span>
                            <button class="select-all-btn" @click="searchModel.selectOrUnSelectItems(mobileSearchLookups.Providers, !searchModel.isAllSelected(mobileSearchLookups.Providers))" v-text='searchModel.isAllSelected(mobileSearchLookups.Providers) ? $t("DeselectAll") : $t("SelectAll")'></button>
                          </div>
                        </li>
                        <li class="items-wrapper">
                          <div class="dropdown-item" :class="{selected: provider.isSelected}" href="#" :title="provider.title" @click="provider.isSelected = !provider.isSelected" v-for="provider in mobileSearchLookups.Providers" :Key="provider.id">
                            {{provider.title|truncate(35)}}
                          </div>
                        </li>
                      </ul>
                    </li>-->

                    <li v-if="mobileSearchLookups.mainSubjects && mobileSearchLookups.mainSubjects.length > 0" @click="openSub($event, null)">
                        <a id="subjects-title" class="topic-title dropdown-toggle">
                            e"
                            {{$t("Subjects")}}
                        </a>
                        <ul class="sub-list">
                            <li class="dropdown-menu-header">
                                <div class="select-all-cont">
                                    <span>{{$t("Subjects")}}</span>
                                </div>
                            </li>
                            <li class="items-wrapper">
                                <div class="subject-item" v-for="subject in mobileSearchLookups.mainSubjects" :class="{selected: subject.isSelected, hasChildren: subject.children.length > 0, 'child-selected': searchModel.isAnySelected(subject.children)}" :key="subject.id" @click="openSub(subject)">
                                    <a @click="openSub($event, subject)">
                                        {{subject.title|truncate(35)}}
                                    </a>
                                    <ul v-if="subject.children != null && subject.children.length > 0" class="sub-list">
                                        <li class="dropdown-menu-header">
                                            <div class="select-all-cont">
                                                <span>{{subject.title|truncate(35)}}</span>
                                                <button class="select-all-btn" @click="subject.isSelected = !subject.isSelected" v-text='subject.isSelected || searchModel.isAllSelected(subject.children) ? $t("DeselectAll") : $t("SelectAll")'></button>
                                            </div>
                                        </li>
                                        <li class="items-wrapper">
                                            <div class="dropdown-item" :class="{selected: subSubject.isSelected || subject.isSelected}" v-for="subSubject in subject.children" :key="subSubject.id" :title="subSubject.title" @click="subSubjectSelectedMobile(subject, subSubject);">
                                                {{subSubject.title|truncate(35)}}
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li @click="openSub($event, null)">
                        <a class="topic-title dropdown-toggle">
                            {{$t("DisplayResults")}}
                        </a>
                        <ul class="sub-list">
                            <li class="dropdown-menu-header">
                                <div class="select-all-cont">
                                    <span>{{$t("DisplayResults")}}</span>
                                </div>
                            </li>
                            <li class="items-wrapper">
                                <div v-for="typeValue in mobileSearchLookups.ResultTypes" :key="typeValue.id" class="dropdown-item" :title="typeValue.title" :class="{selected: typeValue.isSelected}" @click="setSelectedResultType(typeValue)">{{typeValue.title}}</div>
                            </li>
                        </ul>

                    </li>

                    <li v-if="searchModel.isAuthenticated" class="myPref" :class="{selected: searchModel.filterByUserPreferences}" @click="searchModel.filterByUserPreferencesClick()">{{$t("SearchByMyPreferences")}}</li>

                    <li v-if="mobileSearchLookups.sortLookups != null && mobileSearchLookups.sortLookups.length > 0" class="sort-item" @click="openSub($event, null)">
                        <a class="topic-title dropdown-toggle">
                            {{$t("SortBy")}}
                        </a>
                        <ul class="sub-list" aria-labelledby="dropdownMenu2">
                            <li class="dropdown-menu-header">
                                <div class="select-all-cont">
                                    <span>{{$t("SortBy")}}</span>
                                </div>
                            </li>
                            <li class="items-wrapper">
                                <div class="dropdown-item" :class="{selected: searchModel.sortOrder === sortValue.id}" @click="searchModel.sortOrder = sortValue.id" v-for="sortValue in mobileSearchLookups.sortLookups" :key="sortValue.id">{{sortValue.title}}</div>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>

            <div class="dropdown-btns-cont">
                <a class="close-btn" @click="closeFilterMenu($event)">{{$t("Close")}}</a>
                <div>
                    <button @click="onMobileFilter()" class="dropdown-btn filter">{{$t("Filter")}}</button>
                    <button @click="searchModel.removeAllSelectedItems();" class="dropdown-btn delete">{{$t("Clear")}}</button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import SearchModel from "./SearchModel";
    import $ from "jquery";
    //var SlideMenu = window.SlideMenu;
    export default {
        name: "SearchMobileMode",
        data: function () {
            return {
                mobileSearchLookups: Object,
                suggestedSubjectsNavigationIndex: -1,
                actualSearchText: '',
                searchModel: window.searchModel ? window.searchModel : (window.searchModel = new SearchModel(this.$i18n, this.$http, window.searchLookups, this.$router)),
                filterMenuMobile: null
            };
        },
        updated: function () {
            var _this = this;
            this.searchModel.onUpdated();
            var $filterMobileDropdown = $('#mobile-filter-dropdown');
            var $filterMenuMobile = $('#filter-menu-mobile');

            if ($filterMenuMobile.length > 0 && !$filterMenuMobile.find('.slide-menu__slider').length > 0) {
                this.filterMenuMobile = new SlideMenu($filterMenuMobile[0], {
                    showBackLink: false,
                });
                this.filterMenuMobile.open();
                $filterMenuMobile.siblings('.dropdown-menu-header').find('.slide-menu__control').click(function () {
                    _this.filterMenuMobile.back();
                    $('ul.sub-list').addClass('active');

                    if ($filterMenuMobile.find('.slide-menu__submenu--active').length === 1) {
                        $filterMobileDropdown.find('.dropdown-menu').removeClass('sub-opened');
                    }
                });
            }
        },
        methods: {
            openMobilePopup() {
                this.mobileSearchLookups = JSON.parse(JSON.stringify(this.searchModel.searchLookups));
                this.searchModel.uiOpenMobileFilter();
                var $filterMobileDropdown = $('#mobile-filter-dropdown');


                if (this.filterMenuMobile) {
                    this.filterMenuMobile.destroy();
                    $filterMobileDropdown.find('.dropdown-menu').removeClass('sub-opened');
                }
            },
            onMobileFilter() {
                this.searchModel.searchLookups = this.mobileSearchLookups;

                var allMobileSubjects = this.searchModel.searchLookups.mainSubjects;
                var subjectHasChildren = this.searchModel.searchLookups.mainSubjects.filter(function (x) {
                    return x.children && x.children.length > 0;
                });

                for (var i = 0; i < subjectHasChildren.length; i++) {
                    allMobileSubjects = allMobileSubjects.concat(subjectHasChildren[i].children);
                }

                for (var j = 0, length = allMobileSubjects.length; j < length; j++) {
                    var item = allMobileSubjects[j];
                    if (!item) {
                        continue;
                    }

                    var subjectIndex = this.searchModel.allSubjects.findIndex(x => x.id === item.id);
                    if (subjectIndex < 0) {
                        return;
                    }

                    this.searchModel.allSubjects[subjectIndex] = item;
                }

                this.onFilter();
            },
            openSub: function (e, subject) {
                if (subject && (!subject.children || subject.children.length <= 0)) {
                    subject.isSelected = !subject.isSelected;
                    return;
                }

                if ($(e.target).parent().is('.hasChildren') || $(e.target).is('.topic-title')) {
                    $(e.target).parents('ul.sub-list').removeClass('active');
                    $(e.target).next('ul').addClass('active');
                    if ($('.sort-item').find('.slide-menu__submenu--active').length > 0) {
                        $('.select-all-cont .select-all-btn').hide();
                    } else {
                        $('.select-all-cont .select-all-btn').show();
                    }
                    $('#mobile-filter-dropdown').find('.dropdown-menu').addClass('sub-opened');
                }

            },
            closeFilterMenu: function (e) {
                $(e.target).parents('.dropdown-menu').removeClass('show');
                $('body').removeClass('filter-opened');
            },
            setSelectedResultType: function (typeValue) {
                return this.searchModel.setSelectedResultType(typeValue, false);
            },
            subSubjectSelectedMobile(subject, subSubject) {
                if (!subject.isSelected) {
                    subSubject.isSelected = !subSubject.isSelected;
                    this.searchModel.isAllSubSubjectsSelected(subject, subject.children, true);
                    return;
                }

                subject.isSelected = false;
                this.searchModel.selectOrUnSelectItems(subject.children, true);
                subSubject.isSelected = !subSubject.isSelected;
            }
        }
    }
</script>
