<template>
    <div class="item-liked-container" :class="[{'fail': !isSuccess}, className,{'show-action-msg': isPopupOpen}]">
        <div class="item-sucess-liked" v-if="isPopupOpen">
            <div class="icon-close-container exit-same-row" @click="isPopupOpen = false">
                <span class="icon-close"></span>
            </div>
            <div class="success-msg-container">
                <div class="icon-check-container">
                    <span :class="isSuccess ? 'icon icon-check' : 'icon-close'"></span>
                </div>
                <span class="like-action-done-txt" v-if="isSuccess">{{message}}</span>
            </div>
        </div>

        <!--To Add confirmation and comment popup while unsubscribe-->
        <div class="modal fade" id="bookmarks-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="showConfirmation">
            <div class="modal-dialog" role="document">

                <div class="big-card__container big-card__container--with-info action-slide delete-warrning">
                    <div class="pic-slide">
                        <div class="pic-slide--warning-bg">
                        </div>
                    </div>
                    <div class="data-slide ">
                        <div class="waiting-confirmation-slide centered-txt">
                            <div class="warning-txt">
                                <h2 class="silde-title ">
                                    {{$t("UnsusbscriptionConfirmationTitle")}}
                                </h2>
                                <p class="silde-sub-title silde-sub-title--grey confirm-msg">
                                    {{$t("UnsusbscriptionConfirmationMessage")}}
                                </p>
                            </div>


                            <div class="submit-btn-wrp mulitable-btns-container">
                                <button type="submit" class=" submit-account-btn  btn--colored-warning" data-dismiss="modal" @click="deleteSubscription" aria-label="Close">{{$t("Yes")}}</button>
                                <button type="submit" class=" submit-account-btn  btn--disabled" data-dismiss="modal" @click="showConfirmation = false;" aria-label="Close">{{$t("No")}}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>


<script>
    import $ from "jquery";

    export default {
        name: "AddSubscription",
        data: function () {
            return {
                itemId: "",
                className: "",
                isPopupOpen: false,
                isSuccess: false,
                message: '',
                itemToBeDeleted: Object,
                showConfirmation: false
            };
        },
        mounted: function () {
            var $item = $(this.$el)
                .parents(".first-parent:first")
                .find("a.subscribe:first");

            //adding event listner to href in razor views
            $item.click(this.subscribeItem);

            this.itemId = $item.data("id");
            this.className = $item.data("classname");
        },
        methods: {
            subscribeItem($event) {
                if (!window.isAuthenticated) {
                    window.location.href = window.loginUrl;
                    return;
                }

                $event.preventDefault();
                $event.stopPropagation();

                this.itemToBeDeleted = $(this.$el)
                    .parents(".first-parent:first")
                    .find("a.icon-subscribe:first");

                if (this.itemToBeDeleted.hasClass("icon-subscribe-solid")) {
                    this.showConfirmation = true;
                    return;
                }

                //this.showConfirmation = true;
                this.addSubscription();
            },
            addSubscription() {
                var _this = this;
                this.$http.post('/api/v1/newsletters', JSON.stringify(this.itemId),
                    {
                        headers: {
                            'Accept-Language': window.getLang()
                        }
                    })
                    .then(function () {
                        _this.isSuccess = true;
                        _this.isPopupOpen = !_this.isPopupOpen;
                        _this.message = this.$t("SubscriptionSuccess");

                        _this.itemToBeDeleted.removeClass("icon-subscribe");
                        _this.itemToBeDeleted.addClass("icon-subscribe-solid");

                    }).catch(function () {
                        _this.isSuccess = false;
                        _this.isPopupOpen = !_this.isPopupOpen;
                        _this.message = this.$t("SubscriptionFailure");

                    });
            },
            deleteSubscription() {
                var _this = this;

                var deleteSubscriptionViewModel = {
                    itemId: this.itemId,
                    comment: ''
                };

                this.$http.delete('/api/v1/newsletters', { body: deleteSubscriptionViewModel },
                    {
                        headers: {
                            'Accept-Language': window.getLang()
                        }
                    })
                    .then(function () {
                        _this.isSuccess = true;
                        _this.isPopupOpen = !_this.isPopupOpen;
                        _this.message = this.$t("UnsubscriptionSuccess");

                        _this.itemToBeDeleted.removeClass("icon-subscribe-solid");
                        _this.itemToBeDeleted.addClass("icon-subscribe");

                    }).catch(function () {
                        _this.isSuccess = false;
                        _this.isPopupOpen = !_this.isPopupOpen;
                        _this.message = this.$t("UnsubscriptionFailure");

                    });
            }
        }

    }



</script>