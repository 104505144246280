<template>
    <div class="filter-wrap">
        <form class="align-items-end d-flex flex-wrap">
                 
                <div class="flex-grow-1 form-group w-100">
                    <label class="noLabel" :placeholder='$t("SearchFor")' for="txtSearchTerms">{{$t("SearchFor")}}</label>
                   <div id="searchErrorSymbol" class="invalid-feedback">
                              {{$t("ErrorSymbolinSearch")}}
                           </div>
                    <input type="text" maxlength="20" class="form-control"
                           id="txtSearchTerms" name="txtSearchTerms"
                           :placeholder='$t("SearchKey")'
                           v-model="searchModel.pagingParameters.filtrationCriteria.searchTerms" @keyup.enter="onFilter()">
                </div>
            
                <div class="d-flex align-items-end form-group">
                    <button class="btn btn-primary form-control" type="button" @click="onFilter()">
                        {{$t("Search")}}
                    </button>
                </div>
                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("Reset")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-gray" @click="Reset()" type="button">
                            {{$t("Reset")}}
                        </button>
                    </div>
                </div>
                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("SortBy")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            {{$t("SortBy")}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -2px, 0px); top: 0px; left: 0px; will-change: transform;">
                            <a class="dropdown-item" :class="{active: searchModel.sortOrder === sortValue.id}" @click="onSort(sortValue.id)" v-for="sortValue in searchModel.searchLookups.sortLookups" :key="sortValue.id">{{sortValue.title}}</a>
                        </div>
                    </div>
                </div>
                                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("Categories")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            {{$t("Categories")}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -2px, 0px); top: 0px; left: 0px; will-change: transform;">
                              <div class="dropdown-item" dropdown-item v-for="typeValue in searchModel.searchLookups.typeLookups" :key="typeValue.id">
                                 <input type="checkbox" :id="typeValue.id" :value="typeValue.id" v-model="searchModel.pagingParameters.filtrationCriteria.type" @change="onFilterType(typeValue.id)">
                                  <label :for="typeValue.id">{{typeValue.title}}</label>  
                                 </div>
                        </div>
                    </div>
                </div>           
        </form>
    </div>

<!----------------shahd----------->


</template>

<script>
    import MediaModel from "./MediaModel";
    import { PrimeVueLocale } from '../../locales/PrimeVueLocale.js'
    //import $ from "jquery";

    export default {
        name: "MediaMainFilters",
        data: function () {
        
                return {
                    searchModel: window.searchModel ? window.searchModel : (window.searchModel = new MediaModel(this.$i18n, this.$http, window.pagingParameters ,window.searchLookups, this.$router))

            };
        },
        created() {
         
            this.changePrimeVuelocle(window.getLang());
            this.intializeControls();
        },
        updated: function () {

        },
        methods: {

            changePrimeVuelocle(lang) {
                this.$primevue.config.locale = PrimeVueLocale[lang];
            },
            intializeControls: function () {
                return this.searchModel.intializeControls();
            },
            removeTag: function (tag) {
                debugger;
                return this.searchModel.removeTag(tag);
            },
            clearAllControls: function () {
                this.searchModel.clearAllControls();
                this.searchModel.onFilter();
            },
            onFilter: function () {
                var searchInput = document.getElementById("txtSearchTerms");
                var hasSymbol = searchInput.value.match(/[|\\/~^:<>,;?!&%$@*+]/);
                if(hasSymbol)
                {
                    document.getElementById("searchErrorSymbol").style.display = "block";
                   searchInput.style.borderColor = "red";
                   return false;
                }
                return this.searchModel.onFilter();
            },
            onSort: function (sortId) {
                return this.searchModel.onSort(sortId);
            },
            Reset: function () {
                window.location.href = window.location.origin + window.location.pathname
            },
            onFilterType: function (typeId) {
                return this.searchModel.onFilterType(typeId);
            }
            
        },
        components: {

        },
        computed: {
           
        }
    }
</script>
