import Vue from "vue";

import AddSusbscription from "./components/AddSubscription.vue";

import SearchMainFilters from "./components/Search/SearchMainFilters.vue";
import SearchMainSubjectsFilters from "./components/Search/SearchMainSubjectsFilters.vue";
import SearchFiltersBasket from "./components/Search/SearchFiltersBasket.vue";

import NewsMainFilters from "./components/News/NewsMainFilters.vue";
import NationalReportsMainFilters from "./components/NationalReports/NationalReportsMainFilters.vue";
import EventsMainFilters  from "./components/Events/EventsMainFilters.vue";
import MediaMainFilters from "./components/Media/MediaMainFilters.vue";


import Resource from "vue-resource";
import i18n from "./i18n";
import router from "./router";
import Vuelidate from 'vuelidate';


import $ from "jquery";

import PrimeVue from 'primevue/config';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';


Vue.use(PrimeVue);

Vue.component('Calendar', Calendar);
Vue.component('AutoComplete', AutoComplete);


Vue.use(Resource);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

var lang = window.getLang();

Vue.filter("truncate", function (text, length, clamp) {
    clamp = clamp || "…";
    var node = document.createElement("div");
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length - 1) + clamp : content;
});



// if ($("#complaint").length) {
//     var complaintObject = new Vue({
//         i18n,
//         router: router,
//         render: h => h(Complaint)
//     });

//     complaintObject.$i18n.locale = lang;
//     complaintObject.$mount("#complaint");
// }

// $("div.socialMediaSharingItems").each(function (index, element) {
//     var socialMediaSharingItems = new Vue({
//         i18n,
//         render: h => h(SocialMediaSharing)
//     });

//     socialMediaSharingItems.$i18n.locale = lang;

//     socialMediaSharingItems.$mount(element);
// });

// $("div.addBookmark").each(function (index, element) {
//     var addBookmark = new Vue({
//         i18n,
//         render: h => h(AddBookmark)
//     });

//     addBookmark.$i18n.locale = lang;
//     addBookmark.$mount(element);
// });

// if ($("#bookmarkItems").length) {
//     var bookmarkItems = new Vue({
//         i18n,
//         render: h => h(Bookmarks)
//     });

//     bookmarkItems.$i18n.locale = lang;
//     bookmarkItems.$mount("#bookmarkItems");
// }

// if ($("#preferencesItems").length) {
//     var preferencesItems = new Vue({
//         i18n,
//         render: h => h(Preferences)
//     });

//     preferencesItems.$i18n.locale = lang;

//     preferencesItems.$mount("#preferencesItems");
// }

$("div.addSubscription").each(function (index, element) {
    var addSusbscription = new Vue({
        i18n,
        render: h => h(AddSusbscription)
    });

    addSusbscription.$i18n.locale = lang;
    addSusbscription.$mount(element);
});

// if ($("#gReCaptcha").length) {
//     var gReCaptchaObject = new Vue({
//         i18n,
//         router: router,
//         render: h => h(GReCaptchaValidation)
//     });

//     gReCaptchaObject.$i18n.locale = lang;
//     gReCaptchaObject.$mount("#gReCaptcha");
// }


initSearch();
initNews();
initEvents();
initNationalReports();
initMedia();

function initSearch()
{
    if ($("#searchMainFilters").length) {
        var searchMainFilters = new Vue({
            i18n,
            router: router,
            render: h => h(SearchMainFilters)
        });

        searchMainFilters.$i18n.locale = lang;

        searchMainFilters.$mount("#searchMainFilters");
    }

    if ($("#searchMainSubjectsFilters").length) {
        var searchMainSubjectsFilters = new Vue({
            i18n,
            router: router,
            render: h => h(SearchMainSubjectsFilters)
        });

        searchMainSubjectsFilters.$i18n.locale = lang;

        searchMainSubjectsFilters.$mount("#searchMainSubjectsFilters");
    }

    if ($("#searchFiltersBasket").length) {
        var searchFiltersBasket = new Vue({
            i18n,
            router: router,
            render: h => h(SearchFiltersBasket)
        });

        searchFiltersBasket.$i18n.locale = lang;

        searchFiltersBasket.$mount("#searchFiltersBasket");
    }
}

function initNews() {


if ($("#newsMainFilters").length) {
    var mainFilters = new Vue({
        i18n,
        router: router,
        render: h => h(NewsMainFilters)
    });

    mainFilters.$i18n.locale = lang;

    mainFilters.$mount("#newsMainFilters");
}
}



function initMedia() {
  
    if ($("#mediaMainFilters").length) {
        var mainFilters = new Vue({
            i18n,
            router: router,
            render: h => h(MediaMainFilters)
        });

        mainFilters.$i18n.locale = lang;

        mainFilters.$mount("#mediaMainFilters");
    }
}
function initNationalReports() {


    if ($("#NationalReportsMainFilters").length) {
        var mainFilters = new Vue({
            i18n,
            router: router,
            render: h => h(NationalReportsMainFilters)
        });
    
        mainFilters.$i18n.locale = lang;
    
        mainFilters.$mount("#NationalReportsMainFilters");
    }
    }



function initEvents() {


    if ($("#eventsMainFilters").length) {
        var mainFilters = new Vue({
            i18n,
            router: router,
            render: h => h(EventsMainFilters)
        });
    
        mainFilters.$i18n.locale = lang;
    
        mainFilters.$mount("#eventsMainFilters");
    }
    }

// if($("#contactus") .length){
//     var contactusObject = new Vue({
//         i18n,
//         router: router,
//         render: h => h(ContactUs)
//     });

//     contactusObject.$i18n.locale = lang;
//     contactusObject.$mount("#contactus");
// }