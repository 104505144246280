/* eslint-disable */
import $ from "jquery";
var Swiper = window.Swiper;


function SearchModel($i18n, $http, searchLookups, router) {
    var self = this;

    self.$i18n = $i18n;
    self.$http = $http;

    self.searchLookups = searchLookups;
    self.$router = router;
    self.suggestedSubjects = [];
    self.allSubjects = [];
    self.searchText = "";
    self.sortOrder = 0;
    self.isLocked = false;
    self.timer = null;
    self.currentUrl = window.location.origin + window.location.pathname + "?";
    self.isListView = false;
    self.tempList = [];
    self.mainSubjectsCount = self.searchLookups.mainSubjects.length;
    self.isMainSubjectReordered = false;
    self.parentId = 0;
    self.itemId = 0;
    self.filterByUserPreferences = false;
    self.isAuthenticated = window.isAuthenticated;
    self.allowedAliases = "";
    self.startNodeId = 0;
    self.baseDomain = $("body").data("base-domain");




    self.openSortPopup = function () {
        setTimeout(function () {
            self.uiOpenDropdown();
        }, 0);
    };

    self.openPopup = function (event, items) {
        if (!items || items.length <= 0) {
            return;
        }

        const emptyArray = [];
        var tempList = [];
        for (var i = 0, length = items.length; i < length; ++i) {
            var item = items[i];
            if (!item) {
                continue;
            }

            var tempItem = { item: item, isSelected: item.isSelected, children: emptyArray };

            var childrenTempList = [];
            if (item.children && item.children.length > 0) {
                for (var j = 0, jLength = item.children.length; j < jLength; ++j) {
                    var child = item.children[j];
                    if (!child) {
                        continue;
                    }

                    var tempChild = { item: child, isSelected: child.isSelected };

                    childrenTempList.push(tempChild);
                }
            }

            tempItem.children = childrenTempList;

            tempList.push(tempItem);
        }

        self.tempList = tempList;

        setTimeout(function () {
            self.uiOpenDropdown();
        }, 0);
    };

    self.updateOriginalListFromTempList = function () {
        if (!self.tempList || self.tempList.length <= 0) {
            return;
        }

        for (var i = 0; i < self.tempList.length; i++) {
            var tempList = self.tempList[i];
            if (!tempList) {
                continue;
            }

            tempList.item.isSelected = tempList.isSelected;

            if (tempList.children && tempList.children.length > 0) {
                for (var j = 0, jLength = tempList.children.length; j < jLength; ++j) {
                    var child = tempList.children[j];
                    if (!child) {
                        continue;
                    }

                    child.item.isSelected = child.isSelected;
                }
            }
        }

        self.tempList = [];
    };

    self.getSuggestedSubjects = function () {
        if (self.timer) {
            clearTimeout(self.timer);
            self.timer = null;
        }

        self.timer = setTimeout(self.getSubjects, 1000);
    };

    self.checkMainSubjectsOrder = function () {
        var selectedSubjectsInMoreSection = self.searchLookups.mainSubjects.slice(self.mainSubjectsCount, self.searchLookups.mainSubjects.length).reverse().filter(function (x) {
            return x.isSelected || x.children.filter(function (c) {
                return c.isSelected;
            }).length > 0;
        });

        if (selectedSubjectsInMoreSection === null && selectedSubjectsInMoreSection.length <= 0) {
            return;
        }

        for (var i = 0; i < selectedSubjectsInMoreSection.length; i++) {
            self.reorderMainSubjectsList(selectedSubjectsInMoreSection[i]);
        }
    };

    self.reorderMainSubjectsList = function (subject) {
        if (!subject) {
            return;
        }

        self.isMainSubjectReordered = true;

        if (subject.children.length === 0) {
            subject.isSelected = true;
        }

        var itemToBeChanged = self.searchLookups.mainSubjects.slice(0, self.mainSubjectsCount).find(function (x) {
            return x.isSelected === false && x.children.filter(function (c) {
                return c.isSelected;
            }).length === 0;
        });
        var extractedItems = !itemToBeChanged ? self.searchLookups.mainSubjects.slice(0, self.mainSubjectsCount - 1) : self.searchLookups.mainSubjects.slice(0, self.mainSubjectsCount).filter(function (x) {
            return x.id !== itemToBeChanged.id;
        });
        extractedItems.push(subject);
        var otherSubjectsStartIndex = self.mainSubjectsCount - 1; // if all first 10 subjects are selected

        if (itemToBeChanged) {
            otherSubjectsStartIndex = self.mainSubjectsCount;
            extractedItems.push(itemToBeChanged);
        }

        Array.prototype.push.apply(extractedItems, self.searchLookups.mainSubjects.slice(otherSubjectsStartIndex, self.searchLookups.mainSubjects.length).filter(function (x) {
            return x.id !== subject.id;
        }));
        self.searchLookups.mainSubjects = extractedItems;
    };

    self.getSubjects = function () {
        if (self.isLocked) {
            return;
        };

        self.isLocked = true;
        self.$http.get('/api/v1/subjects', {
            params: {
                SearchText: self.searchText
            },
            headers: {
                'Accept-Language': self.$i18n.locale
            }
        }).then(function (response) {
            self.suggestedSubjects = response.data;

            if (self.suggestedSubjects && self.suggestedSubjects.length > 0) {
                self.allSubjects = self.allSubjects.concat(self.suggestedSubjects.filter(function (x) {
                    return self.allSubjects.findIndex(function (s) {
                        return s.id === x.id;
                    }) === -1;
                }));
                $('.keyword-suggestions-cont').css('display', 'block');
            }

            self.isLocked = false;
        }).catch(function () {
            self.isLocked = false;
        });
    };

    self.getSelectedItemsCountString = function (items) {
        if (!items) {
            return '';
        }

        var length = items.filter(function (x) {
            return x.isSelected;
        }).length;

        if (length && length > 0) {
            return '(' + length + ')';
        }

        return '';
    };

    self.onFilter = function () {
        if (self.tempList && self.tempList.length > 0) {
            self.updateOriginalListFromTempList();
        }

        self.currentUrl = window.location.origin + window.location.pathname + '?';
        var queryString = '';

        if (self.searchText) {
            queryString = queryString + "searchTerms=" + encodeURIComponent(self.searchText);
        }

        //queryString = self.constructUrl(queryString, "providers=", self.searchLookups.Providers);
        queryString = self.constructUrl(queryString, "types=", self.searchLookups.contentTypes);
        queryString = self.constructUrl(queryString, "subjects=", self.allSubjects);

        if (self.searchLookups.ResultTypes) {
            queryString = self.constructUrl(queryString, "contentTypes=", self.searchLookups.ResultTypes);
        }

        queryString = queryString + "&pageIndex=0";

        //if (self.searchLookups.RelatedTo && self.searchLookups.RelatedTo.isSelected) {
        //  if (self.itemId && parseInt(self.itemId)) {
        //    queryString = queryString + "&itemId=" + parseInt(self.itemId);
        //  }

        //  if (self.parentId && parseInt(self.parentId)) {
        //    queryString = queryString + "&parentId=" + parseInt(self.parentId);
        //  }
        //}

        if (self.filterByUserPreferences) {
            queryString = queryString + "&filterByUserPreferences=" + self.filterByUserPreferences;
        }

        //if (self.isListView) {
        //  queryString = queryString + "&isListView=1";
        //}

        if (self.sortOrder && parseInt(self.sortOrder)) {
            queryString = queryString + "&sortBy=" + parseInt(self.sortOrder);
        }

        if (self.allowedAliases) {
            queryString = queryString + "&allowedAliases=" + self.allowedAliases;
        }

        if (self.startNodeId) {
            queryString = queryString + "&startNodeId=" + self.startNodeId;
        }

        window.location.href = self.currentUrl + queryString;

        $('.dropdown-menu').hide();
    };

    self.constructUrl = function (queryString, paramName, items) {
        if (!items || items.length <= 0) {
            return queryString;
        }

        var selectedItems = items.filter(function (x) {
            return x.isSelected;
        });

        if (!selectedItems || selectedItems.length <= 0) {
            return queryString;
        }

        for (var i = 0; i < selectedItems.length; i++) {
            queryString = queryString + (!queryString ? '' : '&') + paramName + selectedItems[i].id;
        }

        return queryString;
    };

    self.unselectAllOfSpecificType = function (selectedCategory, updateOriginalList = false) {
        if (!selectedCategory || selectedCategory.length <= 0) {
            return;
        }

        var selectedItems = selectedCategory.filter(function (x) {
            return x.isSelected;
        });

        self.selectOrUnSelectItems(selectedItems, false);

        if (selectedCategory.item && selectedCategory.item.children && selectedCategory.item.children.length > 0) {
            self.selectOrUnSelectItems(selectedCategory.item.children, false);
        }

        updateOriginalList ? self.updateOriginalListFromTempList() : self.tempList = [];
    };

    self.selectAllOfSpecificType = function (selectedCategory) {
        if (!selectedCategory || selectedCategory.length <= 0) {
            return;
        }
        for (var i = 0; i < selectedCategory.length; i++) {
            selectedCategory[i].isSelected = true;
        }
    };


    self.isAnySelected = function (items) {
        return items !== null && items.filter(function (x) {
            return x.isSelected || (x.children && x.children.filter(y => y.isSelected).length > 0);
        }).length > 0;
    };

    self.onSort = function (sortId) {
        self.sortOrder = sortId;
        self.onFilter();
    };

    self.setSelectedResultType = function (typeValue, filter = true) {
        typeValue.isSelected = !typeValue.isSelected;

        if (filter) {
            self.onFilter();
        }
    };

    self.removeAllSelectedItems = function () {
        self.filterByUserPreferences = false;
        self.unselectAllOfSpecificType(self.searchLookups.contentTypes);
        //self.unselectAllOfSpecificType(self.searchLookups.Providers);
        self.unselectAllOfSpecificType(self.allSubjects);
        self.onFilter();
    };

    self.isAllSelected = function (list) {
        return !list ? false : list.length === list.filter(function (x) {
            return x.isSelected;
        }).length;
    };

    self.isAllSubSubjectsSelected = function (subject, subjectChildren, isMobile = false) {
        let list = subjectChildren ? subjectChildren : this.tempList;

        if (subject === null || !subject.children || subject.children.length <= 0 || !list || list.length <= 0) {
            subject.isSelected = !subject.isSelected;

            if (!isMobile) {
                this.onFilter();
            }

            return;
        }

        if (self.isAllSelected(list)) {
            subject.isSelected = true;

            for (var i = 0, length = list.length; i < length; i++) {
                list[i].isSelected = false;
            }
        }
    };


    self.onUpdated = function () {
        var $window = $(window),
            $searchFiltersCont = $('#searchFilters'),
            $dropdown = $searchFiltersCont.find('.dropdown'),
            $keywordsCont = $searchFiltersCont.find('.keyword-suggestions-cont'),
            $subjectsCont = $searchFiltersCont.find('.subjects-cont'),
            $subjectItem = $subjectsCont.find('.subject-item'),
            $selectedItemsSlider = $searchFiltersCont.find('.selected-items-wrp'),
            $resultItemsCont = $('.results-cont'),
            $resultItems = $resultItemsCont.find('.listing-card-wrp'),
            $listingLoader = $searchFiltersCont.find('.listing-loader'),
            $pagination = $searchFiltersCont.find('.pagination-cont');
        var pageIndex = self.$router.history.current.query.pageIndex;


        $(document).click(function (e) {
            if ($('body').hasClass('dimmed') && !($(e.target).hasClass('select-all-btn'))) {
                $("body").removeClass('dimmed');
                $subjectsCont.removeClass('noDimmed');
            }
            if ($keywordsCont.hasClass('shown')) {
                $keywordsCont.hide();
            }
        });
        $("input[type='text']").click(function (e) {
            e.stopPropagation();

            if ($keywordsCont.hasClass('shown')) {
                $keywordsCont.show();
            }
        });

        //if ($resultItems) {
        //    if (pageIndex === '0' || !pageIndex) {
        //        animateOnScroll(8, 4);
        //    } else {
        //        $resultItems.show();
        //        $pagination.css('display', 'inline-flex');
        //    }
        //}

        //function animateOnScroll(initialNum, numToShow) {
        //    $resultItems.slice(0, initialNum).show();
        //    $window.on('scroll', function() {
        //        if ($window.scrollTop() + $window.height() === $(document).height()) {
        //            if ($(".listing-card-wrp:hidden").length > 0) {
        //                $listingLoader.fadeIn();
        //                setTimeout(function() {
        //                    $listingLoader.fadeOut();
        //                    $(".listing-card-wrp:hidden").slice(0, numToShow).fadeIn('slow');
        //                }, 2000);
        //            } else {
        //                $pagination.css('display', 'inline-flex');
        //            }
        //        }
        //    });
        //}



    };
    self.slideSelectedItems = function () {
        var $searchFiltersCont = $('#searchFilters');
        var $selectedItemsSlider = $searchFiltersCont.find('.selected-items-wrp');

        var isDown = false;
        var startX;
        var scrollLeft;
        $selectedItemsSlider.mousedown(function (e) {
            isDown = true;
            $selectedItemsSlider.addClass('active');
            startX = e.pageX - $selectedItemsSlider.offset().left;
            scrollLeft = $selectedItemsSlider.scrollLeft();
        });
        $selectedItemsSlider.mouseleave(function () {
            isDown = false;
            $selectedItemsSlider.removeClass('active');
        });
        $selectedItemsSlider.mouseup(function () {
            isDown = false;
            $selectedItemsSlider.removeClass('active');
        });
        $selectedItemsSlider.mousemove(function (e) {
            if (!isDown) return;
            e.preventDefault();
            var x = e.pageX - $selectedItemsSlider.offset().left;
            var walk = (x - startX) * 3; //scroll-fast

            $selectedItemsSlider.scrollLeft(scrollLeft - walk);
        });

    };

    self.disableButton = function (list) {
        return !(self.isAnySelected(self.tempList) || self.isAnySelected(list));
    };

    self.uiOpenDropdown = function () {
        var $searchFiltersCont = $('#searchFilters'),
            $keywordsCont = $searchFiltersCont.find('.keyword-suggestions-cont'),
            $subjectsCont = $searchFiltersCont.find('.subjects-cont');

        if ($('.dropdown-menu').hasClass('show')) {
            $("body").addClass('dimmed');
            $keywordsCont.hide();
            if ($('.search-popup').find('.multi-select').hasClass('show')) {
                $subjectsCont.addClass('noDimmed');

            }
        } else {
            $("body").removeClass('dimmed');
            $subjectsCont.removeClass('noDimmed');
        }
    };
    self.uiOpenMobileFilter = function () {
        $("body").addClass('filter-opened');
    };

    self.selectOrUnSelectItems = function (items, value) {
        if (!items || items <= 0) {
            return;
        }

        for (var i = 0, length = items.length; i < length; i++) {
            if (!items[i]) {
                continue;
            }
            items[i].isSelected = value;
        }
    };
    self.filterByUserPreferencesClick = function () {
        self.filterByUserPreferences = !self.filterByUserPreferences;

        if (self.filterByUserPreferences) {
            self.unselectAllOfSpecificType(self.allSubjects, false);
        }

        self.onFilter();
    };

    (function () {
        self.allSubjects = self.searchLookups.mainSubjects;
        var subjectHasChildren = self.searchLookups.mainSubjects.filter(function (x) {
            return x.children && x.children.length > 0;
        });

        for (var i = 0; i < subjectHasChildren.length; i++) {
            self.allSubjects = self.allSubjects.concat(subjectHasChildren[i].children);
        }

        if (window.otherSuggestedSubjects) {
            self.allSubjects = self.allSubjects.concat(window.otherSuggestedSubjects);
        }

        self.searchText = self.searchText ? self.searchText : self.$router.history.current.query.searchTerms ? self.$router.history.current.query.searchTerms : '';
        self.checkMainSubjectsOrder();

        if (self.$router.history.current.query.sortBy) {
            self.sortOrder = parseInt(self.$router.history.current.query.sortBy);
        }

        var uiPreferencesCookie = getCookie("UIPreferences");
        var uiPreferences = uiPreferencesCookie ? $.parseJSON(uiPreferencesCookie) : null;


        self.isListView = uiPreferences ? (uiPreferences.isSearchListView ? uiPreferences.isSearchListView : false) : false;//!!self.$router.history.current.query.isListView;

        self.filterByUserPreferences = !!self.$router.history.current.query.filterByUserPreferences;
        self.allowedAliases = self.$router.history.current.query.allowedAliases;

        if (!getIsRtl()) {
            $('.dropdown-menu').addClass('dropdown-menu-right');
        }

        $("div.results-cont").toggleClass("list-view", self.isListView);

        var $cards = $("div.results-cont").find("div.listing-card-wrp");

        self.isListView ? $cards.removeClass('col-lg-4 col-xl-3') : $cards.addClass('col-lg-4 col-xl-3');

        self.itemId = self.$router.history.current.query.itemId;
        self.parentId = self.$router.history.current.query.parentId;
        self.startNodeId = self.$router.history.current.query.startNodeId;


        self.onUpdated();

    })();

    return self;
}

export default SearchModel;
