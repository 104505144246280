
<template>
    <section class="container" v-if="searchModel.searchLookups != null && searchModel.searchLookups.mainSubjects != null && searchModel.searchLookups.mainSubjects.length > 0">

        <div class="sorting_1   fx-row">
            <div class="favorites  fx-row">
                <span v-if="searchModel.isAuthenticated" class="subject-item myPref" :class="{selected: searchModel.filterByUserPreferences}" @click="searchModel.filterByUserPreferencesClick()">
                    {{$t("SearchByMyPreferences")}}
                </span>
                <div class="tags">
                    <span class="tag" v-for="subject in searchModel.searchLookups.mainSubjects.slice(0, searchModel.mainSubjectsCount)" :key="subject.id" :title="subject.title">
                        <div class="subject-item dropdown-toggle" :class="{active: subject.isSelected, 'child-selected': searchModel.isAnySelected(subject.children)}" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="openPopup($event, subject.children, subject); isAllSubSubjectsSelected(subject)">
                            {{subject.title|truncate(16)}}
                        </div>

                        <div v-if="subject.children != null && subject.children.length > 0" class="dropdown-menu multi-select shadow" aria-labelledby="dropdownMenuButton" v-on:click.stop>
                            <div class="dropdown-menu-header">
                                <p class="dropdown-menu-title" :title="subject.title">{{subject.title}}</p>
                                <button class="select-all-btn" @click="currentSubject.isSelected = !currentSubject.isSelected; searchModel.selectOrUnSelectItems(searchModel.tempList, searchModel.isAllSelected(searchModel.tempList))" v-text='currentSubject.isSelected || searchModel.isAllSelected(searchModel.tempList) ? $t("DeselectAll") : $t("SelectAll")'></button>
                            </div>

                            <div class="dropdown-menu-items">
                                <div class="column" v-for="i in Math.ceil(subject.children.length / 8)" :key="i">
                                    <div class="dropdown-item" :class="{selected: itemWrapper.isSelected || currentSubject.isSelected}" v-for="itemWrapper in searchModel.tempList.slice((i - 1) * 8, i * 8)" :key="itemWrapper.item.id" :title="itemWrapper.item.title" @click="subSubjectSelected(subject, itemWrapper)">
                                        {{itemWrapper.item.title|truncate(35)}}
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-btns-cont">
                                <button @click="updateCurrentSubject(subject); onFilter();" class="dropdown-btn filter" :disabled="!(subject.isSelected || currentSubject.isSelected) && searchModel.disableButton(subject.children)">{{$t("Filter")}}</button>
                                <button @click="subject.isSelected = false; unselectAllOfSpecificType(searchModel.tempList, true); onFilter()" class="dropdown-btn delete" :disabled="!(subject.isSelected || currentSubject.isSelected) && searchModel.disableButton(subject.children)">{{$t("Clear")}}</button>
                            </div>
                        </div>

                        <div v-if="searchModel.searchLookups != null && searchModel.searchLookups.mainSubjects.length > 10" class="dropdown more-subjects">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="openPopup($event, searchModel.searchLookups.mainSubjects.slice(searchModel.mainSubjectsCount, searchModel.searchLookups.mainSubjects.length, null))">
                                {{$t("More")}}
                            </button>

                            <div class="dropdown-menu multi-level-dropdown multi-select shadow" v-on:click.stop>

                                <div class="slide-menu" id="filter-menu">
                                    <div class="dropdown-menu-header">
                                        <div class="back-btn-cont">
                                            <span>{{$t("Subjects")}}</span>
                                            <button type="button" class="select-all-btn slide-menu__control" data-action="back" data-target="this">{{$t("Back")}}</button>
                                        </div>

                                        <div class="select-all-cont">
                                            <span></span>
                                            <button class="select-all-btn" @click="fireSelectAllAction($event);"></button>
                                        </div>

                                    </div>
                                    <ul class="dropdown-menu-items">
                                        <li v-for="subjectWrapper in searchModel.tempList" :key="subjectWrapper.id">

                                            <a class="dropdown-item" :class="{hasChildren: subjectWrapper.children.length > 0, selected: subjectWrapper.isSelected}" @click="openSub($event, subjectWrapper)" :title="subjectWrapper.item.title">{{subjectWrapper.item.title|truncate(35)}}</a>
                                            <ul class="sub-menu" v-if="subjectWrapper.children != null && subjectWrapper.children.length > 0">

                                                <li class="dropdown-menu-header dummy">
                                                    <div class="select-all-cont">
                                                        <span>{{subjectWrapper.item.title|truncate(35)}}</span>
                                                        <button class="select-all-btn" @click="subjectWrapper.isSelected = !subjectWrapper.isSelected; searchModel.selectOrUnSelectItems(subjectWrapper.children, searchModel.isAllSelected(subjectWrapper.children))" v-text='subjectWrapper.isSelected || searchModel.isAllSelected(subjectWrapper.children) ? $t("DeselectAll") : $t("SelectAll")'></button>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="dropdown-menu-items test">
                                                        <a class="dropdown-item" :class="{selected: subjectWrapper.isSelected || subjectChildWrapper.isSelected}" v-for="subjectChildWrapper in subjectWrapper.children" :key="subjectChildWrapper.item.id" :title="subjectChildWrapper.item.title" @click="currentSubject = subjectWrapper; subSubjectSelected(subjectWrapper, subjectChildWrapper, true); updateCurrentSubject(subjectWrapper);">{{subjectChildWrapper.item.title|truncate(35)}}</a>
                                                    </div>

                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div class="dropdown-btns-cont">
                                    <button @click="onFilter()" class="dropdown-btn filter" :disabled="searchModel.disableButton(searchModel.searchLookups.mainSubjects.slice(searchModel.mainSubjectsCount, searchModel.searchLookups.mainSubjects.length, null))">{{$t("Filter")}}</button>
                                    <button @click="unselectAllOfSpecificType(searchModel.searchLookups.mainSubjects.slice(searchModel.mainSubjectsCount, searchModel.searchLookups.mainSubjects.length, null)); onFilter()" class="dropdown-btn delete" :disabled="searchModel.disableButton(searchModel.searchLookups.mainSubjects.slice(searchModel.mainSubjectsCount, searchModel.searchLookups.mainSubjects.length, null))">{{$t("Clear")}}</button>
                                </div>
                            </div>

                        </div>

                    </span>
                </div>
            </div>

            <template v-if="searchModel.searchLookups != null && searchModel.searchLookups.sortLookups != null && searchModel.searchLookups.sortLookups.length > 0">

                <span class="btn btn-outline-primary  ub-select" @click="uiOpenDropdown()">
                    <div class="selected">
                        <i class="icon icon-sort"></i>
                        <span class="selected-title">
                            {{$t("SortBy")}}
                        </span>
                        <i class="icon icon-down-arrow"></i>
                    </div>
                    <ul>
                        <li :class="{active: searchModel.sortOrder === sortValue.id}" @click="onSort(sortValue.id)" v-for="sortValue in searchModel.searchLookups.sortLookups" :key="sortValue.id">{{sortValue.title}}</li>
                    </ul>
                </span>

            </template>
        </div>

    </section>


</template>

<script>
    /* eslint-disable */

    import SearchModel from "./SearchModel";
    import $ from "jquery";
    import { Object } from "core-js";

    export default {
        name: "SearchMainSubjectsFilters",
        data: function () {
            return {
                currentSubject: Object,
                searchModel: window.searchModel ? window.searchModel : (window.searchModel = new SearchModel(this.$i18n, this.$http, window.searchLookups, this.$router)),
                filterMenu: null
            };
        },
        mounted: function () {
            if (!getIsRtl()) {
                document.querySelector('.more-subjects .dropdown-menu').classList.add('dropdown-menu-right')
            }
            this.returnSubjectsCount();
            var _this = this;
            //$(window).resize(_this.returnSubjectsCount);

        },
        updated: function () {
            this.searchModel.onUpdated();
            var _this = this;

            var $filterMenu = $('#filter-menu');

            if ($filterMenu.length > 0 && !$filterMenu.find('.slide-menu__slider').length > 0) {


                //this.filterMenu = new SlideMenu($filterMenu[0], {
                //  showBackLink: false,
                //});
                //this.filterMenu.open();
                //$('.slide-menu__control').click(function () {
                //  _this.filterMenu.back();
                //  $('#filter-menu').removeClass('sub-opened');

                //});
            }


        },
        methods: {
            returnSubjectsCount: function returnSubjectsCount() {
                this.searchModel.mainSubjectsCount = this.searchModel.searchLookups.mainSubjects.length;

                var subjectsContWidth = $('.subjects-wrp').outerWidth() - $('.subjects-wrp .more-subjects').outerWidth();
                var accumaltedWidth = 0;
                var subjectItemsNum = 0;

                for (var i = 0; i < this.searchModel.mainSubjectsCount; i++) {
                    if (accumaltedWidth < subjectsContWidth) {
                        subjectItemsNum += 1;
                        accumaltedWidth += $('.subjects-wrp > .dropdown').eq(i).outerWidth();
                    }
                    if (accumaltedWidth > subjectsContWidth) {
                        accumaltedWidth -= $('.subjects-wrp > .dropdown').eq(i).outerWidth();
                        break;
                    }
                }

                this.searchModel.mainSubjectsCount = subjectItemsNum - 1;
                this.searchModel.checkMainSubjectsOrder();

            },
            unselectAllOfSpecificType: function (selectedCategory, updateOriginalList = false) {
                return this.searchModel.unselectAllOfSpecificType(selectedCategory, updateOriginalList);
            },
            isAllSubSubjectsSelected: function (subject, subjectChildren = null) {
                return this.searchModel.isAllSubSubjectsSelected(subject, subjectChildren);
            },
            reorderMainSubjectsList: function (subject) {
                return this.searchModel.reorderMainSubjectsList(subject);
            },
            onFilter: function () {
                return this.searchModel.onFilter();
            },
            constructUrl: function (queryString, paramName, items) {
                return this.searchModel.constructUrl(queryString, paramName, items);
            },
            getSelectedItemsCountString: function (items) {
                return this.searchModel.getSelectedItemsCountString(items);
            },
            onSort: function (sortId) {
                return this.searchModel.onSort(sortId);
            },
            openPopup: function ($event, items, subject) {
                if (subject) {
                    this.currentSubject = JSON.parse(JSON.stringify(subject));
                }
                var $filterMenu = $('#filter-menu');
                if (this.filterMenu) {
                    this.filterMenu.destroy();
                    $filterMenu.removeClass('sub-opened');
                }


                return this.searchModel.openPopup($event, items);
            },
            uiOpenDropdown: function () {
                this.searchModel.openSortPopup();
            },
            openSub: function (e, subjectWrapper) {
                if (!subjectWrapper.children || subjectWrapper.children.length <= 0) {
                    subjectWrapper.isSelected = true;
                    return;
                }
                setTimeout(function () {
                    $('.slide-menu').children('.dropdown-menu-header').find('.select-all-cont .select-all-btn').text($('.slide-menu__submenu--active .dropdown-menu-header.dummy .select-all-btn').text());
                }, 0)
                $('#filter-menu').animate({
                    scrollTop: $(".sub-menu").offset().top
                }, 0, function () {
                    $(this).addClass('sub-opened');

                });

                $('.slide-menu').children('.dropdown-menu-header').find('.select-all-cont span').text($(e.target).siblings('.sub-menu').find('.dropdown-menu-header.dummy span').text());
                $('.slide-menu').children('.dropdown-menu-header').find('.select-all-cont span').attr('title', $(e.target).siblings('.sub-menu').find('.dropdown-menu-header.dummy span').attr('title'));
            },
            fireSelectAllAction: function (e) {
                $("body").addClass('dimmed');
                $('.slide-menu__submenu--active .dropdown-menu-header.dummy .select-all-btn').click();
                setTimeout(function () {
                    $('.slide-menu').children('.dropdown-menu-header').find('.select-all-cont .select-all-btn').text($('.slide-menu__submenu--active .dropdown-menu-header.dummy .select-all-btn').text());
                }, 0)
            },
            subSubjectSelected(subject, subSubject, isWrappedSubject = false) {
                if (!this.currentSubject.isSelected) {
                    subSubject.isSelected = !subSubject.isSelected;
                    this.isAllSubSubjectsSelected(isWrappedSubject ? subject : this.currentSubject, subject.children);
                    return;
                }

                this.currentSubject.isSelected = false;
                this.searchModel.selectOrUnSelectItems(isWrappedSubject ? subject.children : this.searchModel.tempList, true);
                subSubject.isSelected = !subSubject.isSelected;
            },
            updateCurrentSubject(subject) {
                var subjectIndex = this.searchModel.allSubjects.findIndex(x => x.id === subject.id);
                if (subjectIndex < 0) {
                    return;
                }

                this.searchModel.allSubjects[subjectIndex] = this.currentSubject;
            }
        }
    };

</script>
