/* eslint-disable */
//import $ from "jquery";
import { TextControl, DateControl, DropDownControl, pagingParamEnum, AutoCompleteControl, MultipleCheckBoxControl } from "../../common/types.js" 
function MediaModel($i18n, $http, pagingParameters ,searchLookups ,router) {

    var self = this;

    self.$i18n = $i18n;
    self.$http = $http;

    self.searchLookups = searchLookups;
    self.$router = router;
    self.pagingParameters = pagingParameters;

    self.currentUrl = window.location.origin + window.location.pathname + "?";
    self.lang = window.getLang();
    self.isFormDisabled = false;
    self.controls = [];
    self.errors = {}
    self.tags = [];

    self.intializeControls = function () {
        createControls();
        formatTags();
    }

    function createControls() {
        //factoy pattern
        self.controls = []
        let search = new TextControl(pagingParamEnum.searchTerms, 1, self.pagingParameters.filtrationCriteria);
        self.controls.push(search);
        let sort = new DropDownControl(pagingParamEnum.sortCriteria, 2, self.pagingParameters.sortingCriteria, [], null, self.searchLookups.sortLookups);
        self.controls.push(sort);
        let type = new MultipleCheckBoxControl(pagingParamEnum.type, 3, self.pagingParameters.filtrationCriteria, [], null, self.searchLookups.typeLookups);
        self.controls.push(type);
        //overriding
        for (let c in self.controls) {
            //this function deserialize data comes from razor, and add tags for all controls types that contains values
            // each control type implments intialtialize control to deserialize data in its way
            self.controls[c].IntilizeControl();
        }

    }

    function formatTags() {
  
        self.tags = [];
        self.controls.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        for (let i in self.controls) {
            self.tags = self.tags.concat(self.controls[i].tags);
        }
        return self.tags;
    };

    self.removeTag = function (tag) {
        //removing Tag from control Array
     
        //let controlName = tagId.split('_')[0];
        let control = self.controls.find(c => c.name == tag.type);
        control.RemoveControlTag(tag.id);
        self.onFilter();
    };

    self.clearAllControls = function () {
        debugger;
        for (let control of self.controls) {
            control.ClearControl();
        }
    };

    self.onFilter = function () {

        self.currentUrl = window.location.origin + window.location.pathname + '?';

        var queryString = '';

        if (self.pagingParameters.filtrationCriteria.searchTerms) {
            queryString = queryString + "searchTerms=" + encodeURIComponent(self.pagingParameters.filtrationCriteria.searchTerms);
        }

        queryString = queryString + "&pageIndex=0";

        if (self.pagingParameters.sortingCriteria.sortCriteria != null) {
            queryString = queryString + "&sortBy=" + parseInt(self.pagingParameters.sortingCriteria.sortCriteria);
        }

        if (self.pagingParameters.filtrationCriteria.type != null && self.pagingParameters.filtrationCriteria.type.length > 0) {
            let str = self.pagingParameters.filtrationCriteria.type.join("&type=");
            queryString = queryString + "&type=" + str;
        }
        if (self.pagingParameters.filtrationCriteria.relatedMediaId != null) {
          
            queryString = queryString + "&relatedMediaId=" + parseInt(self.pagingParameters.filtrationCriteria.relatedMediaId );
        }
        window.location.href = self.currentUrl + queryString;

    };

    self.onSort = function (sortId) {
        self.pagingParameters.sortingCriteria.sortCriteria = sortId;
        self.onFilter();
    };

    self.onFilterType = function (typeId) {
        if (typeId === 0) // 0 is all element 
        {
            //add element "all" only, remove others
            self.pagingParameters.filtrationCriteria.type = [0];
        }
        else {
            //remove "all" element if exist
            let allElemntIndex = self.pagingParameters.filtrationCriteria.type.findIndex(e => e == 0);
            if (allElemntIndex != -1) {
                self.pagingParameters.filtrationCriteria.type.splice(allElemntIndex, 1);
            }
        }
        self.onFilter();
    };

    function IsValidForm() {
        debugger;
        self.isFormDisabled = false;
        for (let c in self.errors) {
            if (self.errors[c].isError) {
                self.isFormDisabled = true;
                break;
            }
        }

        return !self.isFormDisabled;
    };
    (function () {

    })();

    return self;
}

export default MediaModel;
