<template>
    <div class="filter-wrap mt-6 mb-2">
         <form class="align-items-end d-flex flex-wrap">                       
              <div class="flex-grow-1 form-group w-100">
                    <label class="noLabel" :placeholder='$t("SearchFor")' for="txtSearchTerms">{{$t("SearchFor")}}</label>
                    <div id="searchErrorSymbol" class="invalid-feedback">
                              {{$t("ErrorSymbolinSearch")}}
                           </div>
                    <input type="text" maxlength="40" class="form-control"
                           id="txtSearchTerms" name="txtSearchTerms"
                           :placeholder='$t("SearchKey")'
                           v-model="searchModel.searchText" @keyup.enter="onFilter()">
                </div>
            
                <div class="d-flex align-items-end form-group">
                    <button class="btn btn-primary form-control" type="button" @click="onFilter()">
                        {{$t("SearchButton")}}
                    </button>
                </div>
                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("Reset")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-gray" @click="Reset()" type="button">
                            {{$t("Reset")}}
                        </button>
                    </div>
                </div>
                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("SortBy")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            {{$t("SortBy")}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -2px, 0px); top: 0px; left: 0px; will-change: transform;">
                            <a class="dropdown-item" :class="{active: searchModel.sortOrder === sortValue.id}" @click="onSort(sortValue.id)" v-for="sortValue in searchModel.searchLookups.sortLookups" :key="sortValue.id">{{sortValue.title}}</a>
                        </div>
                    </div>
                </div>
        </form>
    </div>

</template>

<script>
    import SearchMobileMode from './SearchMobileMode';
    import SearchModel from "./SearchModel";
    import $ from "jquery";
    //var SlideMenu = window.SlideMenu;
    export default {
        name: "SearchMainFilters",
        data: function () {
            return {
                mobileSearchLookups: Object,
                suggestedSubjectsNavigationIndex: -1,
                actualSearchText: '',
                searchModel: window.searchModel ? window.searchModel : (window.searchModel = new SearchModel(this.$i18n, this.$http, window.searchLookups, this.$router)),
                filterMenuMobile: null
            };
        },
        updated: function () {
            var _this = this;
            this.searchModel.onUpdated();
            var $filterMobileDropdown = $('#mobile-filter-dropdown');
            var $filterMenuMobile = $('#filter-menu-mobile');

            if ($filterMenuMobile.length > 0 && !$filterMenuMobile.find('.slide-menu__slider').length > 0) {
                this.filterMenuMobile = new SlideMenu($filterMenuMobile[0], {
                    showBackLink: false,
                });
                this.filterMenuMobile.open();
                $filterMenuMobile.siblings('.dropdown-menu-header').find('.slide-menu__control').click(function () {
                    _this.filterMenuMobile.back();
                    $('ul.sub-list').addClass('active');

                    if ($filterMenuMobile.find('.slide-menu__submenu--active').length === 1) {
                        $filterMobileDropdown.find('.dropdown-menu').removeClass('sub-opened');
                    }
                });
            }
        },
        methods: {
            textFunc: function () {
            },
            getSuggestedSubjects: function () {
                if (event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39) {
                    return;
                }

                if (event.keyCode == 38 || event.keyCode == 40) {
                    this.suggestedSubjectsNavigation();
                    return;
                }

                this.actualSearchText = this.searchModel.searchText;
                return this.searchModel.getSuggestedSubjects();
            },
            getSelectedItemsCountString: function (items) {
                return this.searchModel.getSelectedItemsCountString(items);
            },
            openPopup: function ($event, items) {
                this.searchModel.openPopup($event, items);
            },
            onFilter: function () {
                var searchInput = document.getElementById("txtSearchTerms");
                var hasSymbol = searchInput.value.match(/[|\\/~^:<>,;?!&%$@*+]/);
                if(hasSymbol)
                {
                    document.getElementById("searchErrorSymbol").style.display = "block";
                   searchInput.style.borderColor = "red";
                   return false;
                }
                return this.searchModel.onFilter();
            },
            suggestedSubjectsNavigation: function () {
                if (event.keyCode == 38 && this.suggestedSubjectsNavigationIndex >= 0) {
                    this.suggestedSubjectsNavigationIndex--;
                } else if (event.keyCode == 40 && this.suggestedSubjectsNavigationIndex < this.searchModel.suggestedSubjects.length - 1) {
                    this.suggestedSubjectsNavigationIndex++;
                }

                if (this.suggestedSubjectsNavigationIndex === -1) {
                    this.searchModel.searchText = this.actualSearchText;
                    return;
                }

                var selectedItem = this.searchModel.suggestedSubjects[this.suggestedSubjectsNavigationIndex];
                if (selectedItem) {
                    this.searchModel.searchText = selectedItem.title;
                }
            },
            selectSuggestedSubject: function (suggestedSubject) {
                if (!suggestedSubject) {
                    return;
                }

                if (suggestedSubject.IsSubject) {
                    var selectedItem = this.searchModel.allSubjects.filter(x => x.id === suggestedSubject.id);
                    if (selectedItem) {
                        selectedItem[0].isSelected = true;
                    }
                }

                this.searchModel.searchText = suggestedSubject.title;
                this.onFilter();
            },
            openMobilePopup() {
                this.mobileSearchLookups = JSON.parse(JSON.stringify(this.searchModel.searchLookups));
                this.searchModel.uiOpenMobileFilter();
                var $filterMobileDropdown = $('#mobile-filter-dropdown');


                if (this.filterMenuMobile) {
                    this.filterMenuMobile.destroy();
                    $filterMobileDropdown.find('.dropdown-menu').removeClass('sub-opened');
                }
            },
            onSort: function (sortId) {
                return this.searchModel.onSort(sortId);
            },
            Reset: function () {
                window.location.href = window.location.origin + window.location.pathname
            },
            onMobileFilter() {
                this.searchModel.searchLookups = this.mobileSearchLookups;

                var allMobileSubjects = this.searchModel.searchLookups.mainSubjects;
                var subjectHasChildren = this.searchModel.searchLookups.mainSubjects.filter(function (x) {
                    return x.children && x.children.length > 0;
                });

                for (var i = 0; i < subjectHasChildren.length; i++) {
                    allMobileSubjects = allMobileSubjects.concat(subjectHasChildren[i].children);
                }

                for (var j = 0, length = allMobileSubjects.length; j < length; j++) {
                    var item = allMobileSubjects[j];
                    if (!item) {
                        continue;
                    }

                    var subjectIndex = this.searchModel.allSubjects.findIndex(x => x.id === item.id);
                    if (subjectIndex < 0) {
                        return;
                    }

                    this.searchModel.allSubjects[subjectIndex] = item;
                }

                this.onFilter();
            },
            openSub: function (e, subject) {
                if (subject && (!subject.children || subject.children.length <= 0)) {
                    subject.isSelected = !subject.isSelected;
                    return;
                }

                if ($(e.target).parent().is('.hasChildren') || $(e.target).is('.topic-title')) {
                    $(e.target).parents('ul.sub-list').removeClass('active');
                    $(e.target).next('ul').addClass('active');
                    if ($('.sort-item').find('.slide-menu__submenu--active').length > 0) {
                        $('.select-all-cont .select-all-btn').hide();
                    } else {
                        $('.select-all-cont .select-all-btn').show();
                    }
                    $('#mobile-filter-dropdown').find('.dropdown-menu').addClass('sub-opened');
                }

            },
            closeFilterMenu: function (e) {
                $(e.target).parents('.dropdown-menu').removeClass('show');
                $('body').removeClass('filter-opened');
            },
            setSelectedResultType: function (typeValue) {
                return this.searchModel.setSelectedResultType(typeValue, false);
            },
            subSubjectSelectedMobile(subject, subSubject) {
                if (!subject.isSelected) {
                    subSubject.isSelected = !subSubject.isSelected;
                    this.searchModel.isAllSubSubjectsSelected(subject, subject.children, true);
                    return;
                }

                subject.isSelected = false;
                this.searchModel.selectOrUnSelectItems(subject.children, true);
                subSubject.isSelected = !subSubject.isSelected;
            }
        },
        components: {
            'search-mobile-mode': SearchMobileMode
        }
    }
</script>
