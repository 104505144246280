/* eslint-disable */
import $ from "jquery";

function EventsModel($i18n, $http, searchLookups, router) {
    var self = this;

    self.$i18n = $i18n;
    self.$http = $http;

    self.searchLookups = searchLookups;
    self.$router = router;

    self.searchText = "";
    self.dateFrom = "";
    self.dateTo = "";
    self.sortOrder = 0;
    self.category = "";

    self.currentUrl = window.location.origin + window.location.pathname + "?";
  

    self.onFilter = function () {

     
    

        self.currentUrl = window.location.origin + window.location.pathname + '?';
        var queryString = '';

        if (self.searchText) {
            queryString = queryString + "searchTerms=" + encodeURIComponent(self.searchText);
        }

        
        if (self.dateFrom) {
            queryString = queryString + "&dateFrom=" + encodeURIComponent(new Date(self.dateFrom).toLocaleDateString().replace(/\//g, '-'));
        }


        if (self.dateTo) {
            queryString = queryString + "&dateTo=" + encodeURIComponent(new Date(self.dateTo).toLocaleDateString().replace(/\//g, '-'));
        }



        //queryString = self.constructUrl(queryString, "providers=", self.searchLookups.Providers);
        queryString = self.constructUrl(queryString, "types=", self.searchLookups.contentTypes);
         queryString = self.constructUrl(queryString, "subjects=", self.allSubjects);

        // if (self.searchLookups.ResultTypes) {
        //     queryString = self.constructUrl(queryString, "contentTypes=", self.searchLookups.ResultTypes);
        // }

        queryString = queryString + "&pageIndex=0";

        
        if (self.filterByUserPreferences) {
            queryString = queryString + "&filterByUserPreferences=" + self.filterByUserPreferences;
        }

    

        if (self.sortOrder) {
            queryString = queryString + "&sortBy=" + parseInt(self.sortOrder);
        }
        if (self.category) {
            queryString = queryString + "&cat=" + parseInt(self.category);
        }

        if (self.allowedAliases) {
            queryString = queryString + "&allowedAliases=" + self.allowedAliases;
        }

        if (self.startNodeId) {
            queryString = queryString + "&startNodeId=" + self.startNodeId;
        }

       window.location.href = self.currentUrl + queryString;

        $('.dropdown-menu').hide();
    };

    self.constructUrl = function (queryString, paramName, items) {
        if (!items || items.length <= 0) {
            return queryString;
        }

        var selectedItems = items.filter(function (x) {
            return x.isSelected;
        });

        if (!selectedItems || selectedItems.length <= 0) {
            return queryString;
        }

        for (var i = 0; i < selectedItems.length; i++) {
            queryString = queryString + (!queryString ? '' : '&') + paramName + selectedItems[i].id;
        }

        return queryString;
    };

    self.unselectAllOfSpecificType = function (selectedCategory, updateOriginalList = false) {
        if (!selectedCategory || selectedCategory.length <= 0) {
            return;
        }

        var selectedItems = selectedCategory.filter(function (x) {
            return x.isSelected;
        });

        self.selectOrUnSelectItems(selectedItems, false);

        if (selectedCategory.item && selectedCategory.item.children && selectedCategory.item.children.length > 0) {
            self.selectOrUnSelectItems(selectedCategory.item.children, false);
        }

        updateOriginalList ? self.updateOriginalListFromTempList() : self.tempList = [];
    };

    self.selectAllOfSpecificType = function (selectedCategory) {
        if (!selectedCategory || selectedCategory.length <= 0) {
            return;
        }
        for (var i = 0; i < selectedCategory.length; i++) {
            selectedCategory[i].isSelected = true;
        }
    };


    self.isAnySelected = function (items) {
        return items !== null && items.filter(function (x) {
            return x.isSelected || (x.children && x.children.filter(y => y.isSelected).length > 0);
        }).length > 0;
    };

    self.onSort = function (sortId) {
        self.sortOrder = sortId;
       self.onFilter();
    };
    self.onChangeCategory = function (CatId) {
        self.category = CatId;
       self.onFilter();
    };

    self.setSelectedResultType = function (typeValue, filter = true) {
        typeValue.isSelected = !typeValue.isSelected;

        if (filter) {
            self.onFilter();
        }
    };

    self.removeAllSelectedItems = function () {
        self.filterByUserPreferences = false;
        self.unselectAllOfSpecificType(self.searchLookups.contentTypes);
        //self.unselectAllOfSpecificType(self.searchLookups.Providers);
        self.unselectAllOfSpecificType(self.allSubjects);
        self.onFilter();
    };

    self.isAllSelected = function (list) {
        return !list ? false : list.length === list.filter(function (x) {
            return x.isSelected;
        }).length;
    };

    self.isAllSubSubjectsSelected = function (subject, subjectChildren, isMobile = false) {
        let list = subjectChildren ? subjectChildren : this.tempList;

        if (subject === null || !subject.children || subject.children.length <= 0 || !list || list.length <= 0) {
            subject.isSelected = !subject.isSelected;

            if (!isMobile) {
                this.onFilter();
            }

            return;
        }

        if (self.isAllSelected(list)) {
            subject.isSelected = true;

            for (var i = 0, length = list.length; i < length; i++) {
                list[i].isSelected = false;
            }
        }
    };


    self.onUpdated = function () {
        var $window = $(window),
            $searchFiltersCont = $('#searchFilters'),
            $dropdown = $searchFiltersCont.find('.dropdown'),
            $keywordsCont = $searchFiltersCont.find('.keyword-suggestions-cont'),
            $subjectsCont = $searchFiltersCont.find('.subjects-cont'),
            $subjectItem = $subjectsCont.find('.subject-item'),
            $selectedItemsSlider = $searchFiltersCont.find('.selected-items-wrp'),
            $resultItemsCont = $('.results-cont'),
            $resultItems = $resultItemsCont.find('.listing-card-wrp'),
            $listingLoader = $searchFiltersCont.find('.listing-loader'),
            $pagination = $searchFiltersCont.find('.pagination-cont');
        var pageIndex = self.$router.history.current.query.pageIndex;


        $(document).click(function (e) {
            if ($('body').hasClass('dimmed') && !($(e.target).hasClass('select-all-btn'))) {
                $("body").removeClass('dimmed');
                $subjectsCont.removeClass('noDimmed');
            }
            if ($keywordsCont.hasClass('shown')) {
                $keywordsCont.hide();
            }
        });
        $("input[type='text']").click(function (e) {
            e.stopPropagation();

            if ($keywordsCont.hasClass('shown')) {
                $keywordsCont.show();
            }
        });

        //if ($resultItems) {
        //    if (pageIndex === '0' || !pageIndex) {
        //        animateOnScroll(8, 4);
        //    } else {
        //        $resultItems.show();
        //        $pagination.css('display', 'inline-flex');
        //    }
        //}

        //function animateOnScroll(initialNum, numToShow) {
        //    $resultItems.slice(0, initialNum).show();
        //    $window.on('scroll', function() {
        //        if ($window.scrollTop() + $window.height() === $(document).height()) {
        //            if ($(".listing-card-wrp:hidden").length > 0) {
        //                $listingLoader.fadeIn();
        //                setTimeout(function() {
        //                    $listingLoader.fadeOut();
        //                    $(".listing-card-wrp:hidden").slice(0, numToShow).fadeIn('slow');
        //                }, 2000);
        //            } else {
        //                $pagination.css('display', 'inline-flex');
        //            }
        //        }
        //    });
        //}



    };
    self.slideSelectedItems = function () {
        var $searchFiltersCont = $('#searchFilters');
        var $selectedItemsSlider = $searchFiltersCont.find('.selected-items-wrp');

        var isDown = false;
        var startX;
        var scrollLeft;
        $selectedItemsSlider.mousedown(function (e) {
            isDown = true;
            $selectedItemsSlider.addClass('active');
            startX = e.pageX - $selectedItemsSlider.offset().left;
            scrollLeft = $selectedItemsSlider.scrollLeft();
        });
        $selectedItemsSlider.mouseleave(function () {
            isDown = false;
            $selectedItemsSlider.removeClass('active');
        });
        $selectedItemsSlider.mouseup(function () {
            isDown = false;
            $selectedItemsSlider.removeClass('active');
        });
        $selectedItemsSlider.mousemove(function (e) {
            if (!isDown) return;
            e.preventDefault();
            var x = e.pageX - $selectedItemsSlider.offset().left;
            var walk = (x - startX) * 3; //scroll-fast

            $selectedItemsSlider.scrollLeft(scrollLeft - walk);
        });

    };

    self.disableButton = function (list) {
        return !(self.isAnySelected(self.tempList) || self.isAnySelected(list));
    };

    self.uiOpenDropdown = function () {
        var $searchFiltersCont = $('#searchFilters'),
            $keywordsCont = $searchFiltersCont.find('.keyword-suggestions-cont'),
            $subjectsCont = $searchFiltersCont.find('.subjects-cont');

        if ($('.dropdown-menu').hasClass('show')) {
            $("body").addClass('dimmed');
            $keywordsCont.hide();
            if ($('.search-popup').find('.multi-select').hasClass('show')) {
                $subjectsCont.addClass('noDimmed');

            }
        } else {
            $("body").removeClass('dimmed');
            $subjectsCont.removeClass('noDimmed');
        }
    };
    self.uiOpenMobileFilter = function () {
        $("body").addClass('filter-opened');
    };

    self.selectOrUnSelectItems = function (items, value) {
        if (!items || items <= 0) {
            return;
        }

        for (var i = 0, length = items.length; i < length; i++) {
            if (!items[i]) {
                continue;
            }
            items[i].isSelected = value;
        }
    };
    self.filterByUserPreferencesClick = function () {
        self.filterByUserPreferences = !self.filterByUserPreferences;

        if (self.filterByUserPreferences) {
            self.unselectAllOfSpecificType(self.allSubjects, false);
        }

        self.onFilter();
    };

    (function () {
        self.allSubjects = self.searchLookups.mainSubjects;
      

        


        self.searchText = self.searchText ? self.searchText : self.$router.history.current.query.searchTerms ? self.$router.history.current.query.searchTerms : '';
        self.dateFrom = self.dateFrom ? self.dateFrom : self.$router.history.current.query.dateFrom ? self.$router.history.current.query.dateFrom : '';
        self.dateTo = self.dateTo ? self.dateTo : self.$router.history.current.query.dateTo ? self.$router.history.current.query.dateTo : '';

        if (self.$router.history.current.query.sortBy) {
            self.sortOrder = parseInt(self.$router.history.current.query.sortBy);
        }
        if (self.$router.history.current.query.cat) {
            self.category = parseInt(self.$router.history.current.query.cat);
        }

        var uiPreferencesCookie = getCookie("UIPreferences");
        var uiPreferences = uiPreferencesCookie ? $.parseJSON(uiPreferencesCookie) : null;


        self.isListView = uiPreferences ? (uiPreferences.isSearchListView ? uiPreferences.isSearchListView : false) : false;//!!self.$router.history.current.query.isListView;

        self.filterByUserPreferences = !!self.$router.history.current.query.filterByUserPreferences;
        self.allowedAliases = self.$router.history.current.query.allowedAliases;

        if (!getIsRtl()) {
            $('.dropdown-menu').addClass('dropdown-menu-right');
        }

        $("div.results-cont").toggleClass("list-view", self.isListView);

        var $cards = $("div.results-cont").find("div.listing-card-wrp");

        self.isListView ? $cards.removeClass('col-lg-4 col-xl-3') : $cards.addClass('col-lg-4 col-xl-3');

        self.itemId = self.$router.history.current.query.itemId;
        self.parentId = self.$router.history.current.query.parentId;
        self.startNodeId = self.$router.history.current.query.startNodeId;


        self.onUpdated();

    })();

    return self;
}

export default EventsModel;
