/* eslint-disable */
//import $ from "jquery";

function NationalReportsModel($i18n, $http, searchLookups,categories, router) {
    var self = this;

    self.$i18n = $i18n;
    self.$http = $http;

    self.searchLookups = searchLookups;
    self.categories = categories;
    self.$router = router;
    self.searchText = "";
    self.dateFrom = "";
    self.dateTo = "";
    self.category="";
    self.sortOrder = 0;
    self.currentUrl = window.location.origin + window.location.pathname + "?";

   self.onFilter = function () {
    debugger;
        self.currentUrl = window.location.origin + window.location.pathname + "?";

        var queryString = '';

        if (self.category) {
            queryString = queryString + "type=" + encodeURIComponent(self.category);        }

        if (self.searchText) {
            queryString = queryString + "&searchTerms=" + encodeURIComponent(self.searchText);
        }

        if (self.dateFrom) {
            queryString = queryString + "&dateFrom=" + encodeURIComponent(new Date(self.dateFrom).toLocaleDateString().replace(/\//g, '-'));
        }


        if (self.dateTo) {
            queryString = queryString + "&dateTo=" + encodeURIComponent(new Date(self.dateTo).toLocaleDateString().replace(/\//g, '-'));
        }

        queryString = queryString + "&pageIndex=0";

        if (self.sortOrder!=null) {
            queryString = queryString + "&sortBy=" + parseInt(self.sortOrder);
        }

       window.location.href = self.currentUrl + queryString;
    };

    self.onSort = function (sortId) {
        debugger;
        self.sortOrder = sortId;
        self.onFilter();
    };

    self.onChangeCat = function (cat) {
        debugger;
        self.category = cat;
        self.onFilter();
    };

    (function () {
        self.searchText = self.searchText ? self.searchText : self.$router.history.current.query.searchTerms ? self.$router.history.current.query.searchTerms : '';
        self.dateFrom = self.dateFrom ? self.dateFrom : self.$router.history.current.query.dateFrom ? self.$router.history.current.query.dateFrom : '';
        self.dateTo = self.dateTo ? self.dateTo : self.$router.history.current.query.dateTo ? self.$router.history.current.query.dateTo : '';

        if (self.$router.history.current.query.sortBy) {
            self.sortOrder = parseInt(self.$router.history.current.query.sortBy);
        }
        if (self.$router.history.current.query.type) {
            self.category = self.$router.history.current.query.type
        }
    })();

    return self;
}

export default NationalReportsModel;
