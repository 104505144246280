<template>
    <div class="filter-wrap">
        <form class="align-items-end d-flex flex-wrap">
            
                <div class="form-group w-50">
                    <label for="exampleFormControlInput1">{{$t("From")}}</label>
                    <Calendar id="exampleFormControlInput1" v-model="searchModel.dateFrom" :placeholder='$t("Choose")' :manualInput="false" :showIcon="true" icon="icon-pick-date" />
                </div>
                <div class="form-group w-50">
                    <label for="exampleFormControlInput1">{{$t("To")}}</label>
                      <div id="dateToError" class="invalid-feedback">
                              {{$t("DateFromMoreDateTo")}}
                           </div>
                    <Calendar id="dateTo" v-model="searchModel.dateTo" :placeholder='$t("Choose")' :manualInput="false" :showIcon="true" icon="icon-pick-date" />
                </div>
      
                <div class="flex-grow-1 form-group w-100">
                    <label class="noLabel" :placeholder='$t("SearchFor")' for="txtSearchTerms">{{$t("SearchFor")}}</label>
                     <div id="searchErrorSymbol" class="invalid-feedback">
                              {{$t("ErrorSymbolinSearch")}}
                           </div>
                    <input type="text" maxlength="20" class="form-control"
                           id="txtSearchTerms" name="txtSearchTerms"
                           :placeholder='$t("SearchKey")'
                           v-model="searchModel.searchText" @keyup.enter="onFilter()">
                          
                </div>
            
                <div class="d-flex align-items-end form-group">
                    <button class="btn btn-primary form-control" type="button" @click="onFilter()">
                        {{$t("Search")}}
                    </button>
                </div>
                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("Reset")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-gray" @click="Reset()" type="button">
                            {{$t("Reset")}}
                        </button>
                    </div>
                </div>
                <div class="d-flex align-items-end form-group">
                    <label class="d-none" for="dropdownMenuButton">{{$t("SortBy")}}</label>
                    <div class="dropdown">
                        <button class="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            {{$t("SortBy")}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="top-start" style="position: absolute; transform: translate3d(0px, -2px, 0px); top: 0px; left: 0px; will-change: transform;">
                            <a class="dropdown-item" :class="{active: searchModel.sortOrder === sortValue.id}" @click="onSort(sortValue.id)" v-for="sortValue in searchModel.searchLookups.sortLookups" :key="sortValue.id">{{sortValue.title}}</a>
                        </div>
                    </div>
                </div>
            
        </form>
    </div>
</template>

<script>
    import NewsModel from "./NewsModel";
    import { PrimeVueLocale } from '../../locales/PrimeVueLocale.js'
    //import $ from "jquery";

    export default {
        name: "NewsMainFilters",
        data: function () {
            return {
                mobileSearchLookups: Object,
                actualSearchText: '',
                searchModel: window.searchModel ? window.searchModel : (window.searchModel = new NewsModel(this.$i18n, this.$http, window.searchLookups, this.$router)),
            };
        },
        created() {
            this.changePrimeVuelocle(window.getLang());
        },
        updated: function () {
            //var _this = this;

            this.searchModel.onUpdated();
        },
        methods: {
            textFunc: function () {
            },
            onFilter: function () {
                var searchInput = document.getElementById("txtSearchTerms");
                var hasSymbol = searchInput.value.match(/[|\\/~^:<>,;?!&%$@*+]/);
                if(hasSymbol)
                {
                    document.getElementById("searchErrorSymbol").style.display = "block";
                   searchInput.style.borderColor = "red";
                   return false;
                }
                if(new Date(this.searchModel.dateFrom) > new Date(this.searchModel.dateTo))
                {
                    document.getElementById("dateToError").style.display = "block";
                    document.getElementById("dateTo").style.borderColor = "red";
                   return false;
                }
                return this.searchModel.onFilter();
            },
            onSort: function (sortId) {
                return this.searchModel.onSort(sortId);
            },
            Reset: function () {
                window.location.href = window.location.origin + window.location.pathname
            },
            changePrimeVuelocle(lang) {
                this.$primevue.config.locale = PrimeVueLocale[lang];
            }
        },
        components: {

        }
    }
</script>
