export const pagingParamEnum = {

    dateFrom: "dateFrom",
    dateTo: "dateTo",
    searchTerms: "searchTerms",
    sortCriteria: "sortCriteria",
    locations: "locations",
    type:"type"

}

export class Control {

    constructor(name, order, BindingObject, tags = []) {
        this.name = name;
        this.order = order;
        this.BindingObject = BindingObject;
        this.tags = tags;
    }
    set bindingValue(value) {
        this.BindingObject[this.name] = value;
    }
    get bindingValue() {
        return this.BindingObject[this.name];
    }
    IntilizeControl() {
 
    }
    ClearControl() {
        //debugger;
        this.bindingValue = null;
    }
    AddControlTag(type,tagText, tagId) {
        //debugger
        //Adding/Updating tag into control tags Arrays
        tagId = tagId == undefined ? `${this.tags.length}_${tagText}` : tagId;
        let tagIndex = this.tags.findIndex(t => t.id == tagId);
        if (tagIndex == -1) {
            let tag=this.CreateTag(tagId, type, tagText);
            this.tags.push(tag);
        }
        else {
            this.tags[tagIndex].title = tagText;
        }
    }
    RemoveControlTag(tagId) {
       // debugger;
        //removing Tag from control Array
        let tagIndex = this.tags.findIndex(t => t.id == tagId);
        if (tagIndex != -1) {
            this.tags.splice(tagIndex, 1);
        }
        this.ClearControl();
    }
    CreateTag(id, type, text) {
        debugger;
        let tag = {
            type: type ,
            id: id,
            title: text
        }
        return tag;
    }
    UpdateAllControlTags(tags) {
        this.tags = tags;
        // self.FormatTags();
    }
}
export class DateControl extends Control {

    constructor(name, order, BindingObject,  tags = []) {
        super(name, order, BindingObject, tags );
    }

    IntilizeControl() {
        
        let date = this.bindingValue;
        if (date) {
            date = new Date(date);
            this.AddControlTag(this.name, date.toLocaleDateString('en-US'), `${this.name}_${this.name}`);
            //deseialize date into Iso date formate to be binded in primeVue calendar 
            this.bindingValue = date;
        }
        super.IntilizeControl();

    }
    ClearControl() {
        super.ClearControl();
    }

}
export class TextControl extends Control {
    
    constructor(name, order, BindingObject, tags = [])  {
        super(name, order, BindingObject,  tags);
    }

    IntilizeControl() {
        debugger;
        if (this.bindingValue) {
            this.AddControlTag(this.name, this.bindingValue, `${this.name}_${this.name}`);
        }
        super.IntilizeControl();
    }
    ClearControl() {
       // debugger;
        super.ClearControl();
    }

}
export class DropDownControl extends Control {

    constructor(name, order, BindingObject, tags = [], defaultSelectedValue, values = []) {
        super(name, order, BindingObject, tags);
        this.values = values;
        this.defaultSelectedValue = defaultSelectedValue;
    }

    IntilizeControl() {
        if (this.bindingValue != undefined && this.bindingValue != null) {
           // debugger;
            let value = this.values.find(s => this.bindingValue == s.id);

            this.AddControlTag(this.name,value.title, `${this.name}_${this.name}`);
        }
        super.IntilizeControl();
       
    }
    ClearControl() {
       // debugger;
        //setdefut
        if (this.defaultSelectedValue)
            this.bindingValue = this.defaultSelectedValue;
        else
            super.ClearControl();
    }
    
}

export class AutoCompleteControl extends Control {

    constructor(name, order, BindingObject, bindingArrObj, arrName) {
        super(name, order, BindingObject, []);
        this.bindingArrObj = bindingArrObj;
        this.arrName = arrName;
    }
    set bindingArray(value) {
        this.bindingArrObj[this.arrName] = value;
    }
    get bindingArray() {
        return this.bindingArrObj[this.arrName];
    }
    IntilizeControl() {
        debugger;
        if (this.bindingValue == null)
            this.bindingValue = [];
        this.ReformatTagsArray();
        super.IntilizeControl();

    }
    ReformatTagsArray() {

        if (this.bindingValue != undefined && this.bindingValue != null) {
            debugger;
            for (let i in this.bindingArray) {
                this.bindingArray[i].type = this.name;
            }
        }
        this.tags = this.bindingArray;
    }
  
    ClearControl() {
         debugger;
     
           this.bindingValue = [];
           this.tags = [];
           this.bindingArray = [];
            //super.ClearControl();
    }
    RemoveControlTag(tagId) {
         debugger;
        //removing Tag from control Array
        let tagIndex = this.tags.findIndex(t => t.id == tagId);
        if (tagIndex != -1) {
            this.tags.splice(tagIndex, 1);
            this.BindingObject[this.name].splice(tagIndex,1)
        }
        this.bindingArray = this.tags ;
    }

    AddControlTag(type, tagText, tagId) {
        //debugger
        //Adding/Updating tag into control tags Arrays
        tagId = tagId == undefined ? `${this.tags.length}_${tagText}` : tagId;
        let tagIndex = this.tags.findIndex(t => t.id == tagId);
        if (tagIndex == -1) {
            let tag = this.CreateTag(tagId, type, tagText);
            this.BindingObject[this.name].push(tagId);
            this.tags.push(tag);
        }
        else {
            this.tags[tagIndex].title = tagText;
        }
        this.bindingArray = this.tags;

    }

}


export class MultipleCheckBoxControl extends Control {

    constructor(name, order, BindingObject, tags = [], defaultSelectedValue, values = []) {
        super(name, order, BindingObject, tags);
        this.values = values;
        this.defaultSelectedValue = defaultSelectedValue;
    }

    IntilizeControl() {
        debugger;
        if (this.bindingValue == null)
            this.bindingValue = [];
        this.ReformatTagsArray();
        super.IntilizeControl();

    }
    ReformatTagsArray() {
        debugger;
        if (this.bindingValue != undefined && this.bindingValue != null) {
            debugger;
            this.tags = [];
            for (let i in this.bindingValue) {
                let value = this.values.find(s => this.bindingValue[i] == s.id);
                let tag = super.CreateTag(value.id, this.name, value.title)
                this.tags.push(tag);
            }
        }
        
    }
    ClearControl() {
        // debugger;
        //setdefut
        if (this.defaultSelectedValue)
            this.bindingValue = this.defaultSelectedValue;
        else
            this.bindingValue = [];
    }

    RemoveControlTag(tagId) {
        debugger;
        //removing Tag from control Array
        let tagIndex = this.tags.findIndex(t => t.id == tagId);
        if (tagIndex != -1) {
            this.tags.splice(tagIndex, 1);
            this.bindingValue.splice(tagIndex, 1);
        }
    }
    AddControlTag() {
        super.AddControlTag(type, tagText, tagId)
    }

}